import React, { Component } from 'react';
import { PointsBar } from '..';
import hud_up from '../../files/HUD/motif_HUD_.png'
// import hud_down from '../../files/HUD/motif_miroirHUD.png'
// import hud_left from '../../files/HUD/motif_HUD90.png'
// import hud_right from '../../files/HUD/motif_HUD-90.png'
export default class HUD extends Component {
    returnHome = () => {
        this.props.backToHome()
    }
    render() {
        const { nb_oeuvres_seen, nb_oeuvres_total } = this.props;
        return (
            <div className='hud'>
                <div className='hud-borders'>
                    <img className='border up' src={hud_up} alt="hud up"/>
                    {/* <img className='border down' src={hud_down} /> */}
                    {/* <img className='border left' src={hud_left} /> */}
                    {/* <img className='border right' src={hud_right} /> */}
                </div>
                <div className='buttons'>
                    <button onClick={this.returnHome} className='back-home'>Retour à l'accueil</button>
                </div>
                <div className='nb_oeuvre_seen'>
                    <PointsBar nb_found={nb_oeuvres_seen} nb_total={nb_oeuvres_total} />
                </div>

            </div>
        );
    }
}
