import React, { Component } from "react";
import arrow_black from '../../static_assets/icons/arrow.png'
// import arrow_blue from '../../static_assets/icons/arrow-blue.png'
export default class Destination extends Component {
    render() {
        const { position, rotation, goesTo, width, height } = this.props.destination
        return (
            <a-entity id={`fleche-${goesTo}`} position={position} rotation={rotation} nt2-debug={`destination: ${goesTo};`} destination-arrow={`destination: ${goesTo};`}>
                <a-image id={`destination-${goesTo}`} src={arrow_black} width={width} height={height} ></a-image>
            </a-entity >
        )

    }
}