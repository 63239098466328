import React, { Component } from "react";

import { getLocation } from "../../services/api.service";
import { Oeuvre, Destination } from ".";
export default class Scene extends Component {
    constructor(props) {
        super(props);
        this.state = {
            background: null,
            name: "black",
            oeuvres: [],
            destinations: [],
            loading: true
        }

    }
    componentDidMount = () => {
        const background = document.getElementById('background');
        background.addEventListener('materialtextureloaded', () => {
            this.setState({ loading: false });

        });

        this.setState({ loading: true },
            this.setLocationInfos(this.props.name)
        )
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.name !== this.props.name) {
            this.setState({ loading: true }, () => {
                this.setLocationInfos(this.props.name);
            })
        }
    }
    setLocationInfos = (key) => {
        if (key) {
            const { name, background, destinations, oeuvres } = getLocation(key)
            this.setState({ name, background, destinations, oeuvres });
        }
        else {
            this.setState({ loading: false })
        }
    }
    render() {
        const { background, oeuvres, destinations, loading } = this.state
        const { show_last_oeuvre, last_oeuvre, callback_lastoeuvre } = this.props
        return (
            <a-scene timeout="2000" loading-screen="dotsColor: white; backgroundColor: black">
                <a-sky id="background" src={background} rotation="0 -130 0"></a-sky>
                {loading ? "" :
                    <a-entity id="content">
                        {Object.keys(oeuvres).length > 0 &&
                            (
                                Object.keys(oeuvres).map((key) => {
                                    const coordinates_oeuvre = oeuvres[key];
                                    return (
                                        (show_last_oeuvre || last_oeuvre.name !== key) &&
                                        <Oeuvre key={`oeuvre-${key}`} coordinates={coordinates_oeuvre} name={key} isLast={last_oeuvre.name === key} callback_lastoeuvre={callback_lastoeuvre} />
                                    );
                                }
                                )
                            )
                        }
                        {destinations.length > 0 &&
                            destinations.map((destination, i) =>
                                <Destination key={`destination-${i}`} destination={destination} />
                            )

                        }
                    </a-entity>
                }
                <a-entity light="type: ambient; color: #BBB"></a-entity>
                <a-entity id="camera-rotate-wasd-controls">
                    <a-camera camera="active: true" look-controls="mouseEnabled: true; touchEnabled: true; hmdEnabled: true; magicWindowTrackingEnabled: true; enabled: true;" wasd-controls="enabled:false;acceleration:1000" position="0 1.6 0" />
                </a-entity>

                <a-entity cursor="rayOrigin:mouse"></a-entity>
                <a-entity laser-controls="hand: right"></a-entity>
            </a-scene >
        )

    }
}