import React, { Component } from 'react';
import { AProposInterface, OeuvreInterface, GuideInterface, BiographiesInterface } from '.';

import legs_logo from '../../static_assets/icons/legs-logo.png'
import legs_logo_bg from '../../static_assets/icons/legs-logo-bg.png'
export default class AccueilInterface extends Component {
    commencerHistoire = () => {
        this.props.setAudioZone('caverne')
        this.props.openInterface(<OeuvreInterface name={'burning_earth'} {...this.props} closeInterface={this.props.firstOeuvreClose} isFirst={true}/>, 'fog', true)
    }
    openApropos = () => {
        this.props.openInterface(<AProposInterface {...this.props} />, 'fog')
    }
    openGuide = () => {
        this.props.openInterface(<GuideInterface {...this.props} />, 'fog')
    }
    openBiographies = () => {
        this.props.openInterface(<BiographiesInterface {...this.props} />, 'fog')
    }
    render() {
        return (
            <div className="accueil">
                <div className='legs-logo'>
                    <img src={legs_logo_bg} className="bg" alt="logo-background"/>
                    <img src={legs_logo} className="logo" alt="logo"/>
                </div>
                <div className='window window-home-btns'>
                    <button onClick={this.openApropos}>À propos</button>
                    <button onClick={this.openGuide}>Guide de navigation</button>
                    <button onClick={this.openBiographies}>Biographies des auteur·ices et artistes</button>
                    <button onClick={this.commencerHistoire}>Explorer</button>
                </div>
            </div>
        );
    }
}
