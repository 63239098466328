import React, { Component } from 'react';
import { Scene } from './components';
import { OeuvreInterface } from './components/interfaces';

import arrow_black from './static_assets/icons/arrow.png'
import arrow_blue from './static_assets/icons/arrow-blue.png'

const AFRAME = window.AFRAME


export default class VRScene extends Component {
    setActiveOeuvre = (oeuvre) => {
        this.props.openInterface(<OeuvreInterface setAudioZone={this.props.setAudioZone} name={oeuvre} closeInterface={this.props.closeInterface} openInterface={this.props.openInterface} callback_lastoeuvre={this.props.callback_lastoeuvre} isLast={this.props.last_oeuvre.name === oeuvre} />, 'fog', true)
        this.props.addOeuvreSeen(oeuvre)
    }

    componentDidMount = () => {

        document.addEventListener('keydown', (event) => {
            const el = document.querySelector('#camera-rotate-wasd-controls');
            let rotation = el.getAttribute('rotation');
            switch (event.key) {
                case 'ArrowUp':
                case 'w':
                    rotation.x = rotation.x + 3;
                    break;
                case 'ArrowDown':
                case 's':
                    rotation.x = rotation.x - 3;
                    break;
                case 'ArrowLeft':
                case 'a':
                    rotation.y = rotation.y + 3;
                    break;
                case 'ArrowRight':
                case 'd':
                    rotation.y = rotation.y - 3;
                    break;
                default:
                    break;
            }
            el.setAttribute('rotation', rotation);


        });
        const parent = this;
        window.addEventListener('contextmenu', (event) => {
            event.preventDefault()
        })

        if (!AFRAME.components['destination-arrow']) {
            AFRAME.registerComponent('destination-arrow', {
                schema: {
                    destination: { type: 'string', default: 'cave' },
                },
                init: function () {
                    const data = this.data;
                    const el = this.el;

                    el.addEventListener('click', (e) => {
                        parent.props.setActiveScene(data.destination)
                    });

                    el.addEventListener('mouseenter', (evt) => {
                        el.children[0].setAttribute('src', arrow_blue);
                    });
                    el.addEventListener('mouseleave', (evt) => {
                        el.children[0].setAttribute('src', arrow_black);
                    });
                    // el.addEventListener('keydown', (event) => {
                    //     console.log(event.keycode)

                    // });
                },
            });
        }
        if (!AFRAME.components['oeuvre']) {
            AFRAME.registerComponent('oeuvre', {
                schema: {
                    name: { type: 'string', default: 'cave' },
                },
                init: function () {
                    const data = this.data;
                    const el = this.el;
                    el.addEventListener('click', (evt) => {
                        parent.setActiveOeuvre(data.name)
                    });
                },
            });
        }

    }
    render() {
        const { active_scene, show_last_oeuvre, last_oeuvre } = this.props
        return (
            <Scene name={active_scene} show_last_oeuvre={show_last_oeuvre} last_oeuvre={last_oeuvre} />
        );
    }
}