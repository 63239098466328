import React, { Component } from 'react';
import { getAuteurByName, getOeuvre, getTexteByName } from "../../services/api.service";
import ImageGallery from 'react-image-gallery';
import { BiographieSingleInterface, PeritexteInterface } from '.';

export default class OeuvreInterface extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            text_index: -1,
            texte: {},
            oeuvre: {}
        }

    }
    prevText = (min) => {
        if (this.state.text_index > min) {
            this.setState({ text_index: this.state.text_index - 1 })
        }

    }
    nextText = (limit) => {
        const max = limit - 1;
        if (this.state.text_index < max) {
            this.setState({ text_index: this.state.text_index + 1 })
        }
        else {
            if (this.state.oeuvre.next_oeuvre)
                this.ouvrirNextOeuvre()
            else
                this.closeWindow()
        }
    }
    componentDidMount = () => {
        //Mettre les infos des oeuvres
        this.setState({ loading: true },
            () => this.setOeuvreInfos(this.props.name));
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.name !== this.props.name) {
            //Mettre les infos des oeuvres
            this.setState({ loading: true },
                () => this.setOeuvreInfos(this.props.name));
        }
    }
    setOeuvreInfos = (name) => {
        if (name) {
            const oeuvre = getOeuvre(name)
            const texte = getTexteByName(oeuvre.text_associer)
            let text_index = oeuvre.images.length > 0 ? -1 : 0;
            if (this.props.text_index)
                text_index = this.props.text_index
            this.setState({ oeuvre, texte, text_index, loading: false });
        }
    }
    closeWindow = () => {
        if (this.props.isLast) {
            this.props.closeInterface(true, this.state.texte.text_transition, this.props.callback_lastoeuvre)
        }
        else {
            this.props.closeInterface(true, this.state.texte.text_transition)
        }
    }
    ouvrirPeritexte = () => {
        this.props.openInterface(<PeritexteInterface {...this.props} name={this.props.name} goBack={this.reouvrirThisOeuvre} />, 'fog')
    }
    ouvrirNextOeuvre = () => {
        this.props.openInterface(<OeuvreInterface {...this.props} name={this.state.oeuvre.next_oeuvre} />, 'fog')

    }
    ouvrirAuteur = (e) => {
        e.preventDefault();
        const name = e.target.dataset.auteur_name
        this.props.openInterface(<BiographieSingleInterface {...this.props} auteur_key={name} callback={this.reouvrirThisOeuvre} />, 'fog')

    }
    reouvrirThisOeuvre = () => {
        this.props.openInterface(<OeuvreInterface {...this.props} text_index={this.state.text_index} />, 'fog')

    }
    renderBoutons = (min, max, text_index) => {
        return (
            max > 0
                ? text_index >= 0 &&
                <div className='window-buttons'>
                    {text_index > min &&
                        <button className='text-button' onClick={(e) => this.prevText(min)}>←</button>
                    }
                    {text_index < max &&
                        <button className='text-button' onClick={(e) => this.nextText(max)}>→</button>
                    }
                    {/* <button className='oeuvre-close-btn' onClick={this.closeWindow}>✕</button> */}
                </div>
                :
                <div className='window-buttons'>
                    <button className='access-peritext-btn' onClick={this.ouvrirPeritexte}>i</button>
                </div>
        )

    }
    renderTexteInfos = (texte, text_index, min, max) => {
        const { titre, temporel, localisation, auteur_associer, text } = texte
        const auteur = getAuteurByName(auteur_associer)
        return (
            text_index >= 0 &&
            <div className='texte-infos'>
                <h2 className='titre'>{titre}</h2>
                {
                    text_index < 1 &&
                    <div>
                        {
                            (auteur_associer.length > 0 && auteur) &&
                            <div className='auteur' onClick={this.ouvrirAuteur} data-auteur_name={auteur_associer}>{`${auteur.prenom} ${auteur.nom}`}</div>
                        }
                        <div className='temporel' dangerouslySetInnerHTML={{ __html: temporel }}></div>
                        <div className='localisation'>{localisation}</div>
                    </div>
                }
                <div className='text'>
                    {text[text_index].split("\n").map(l => <p>{l}</p>)}
                </div>
                {this.renderBoutons(min, max, text_index)}
            </div>
        )
    }
    renderOeuvreInfos = (oeuvre, text_index, max) => {
        const { images, vignette, photographie } = oeuvre
        return (
            images.length > 0 &&
            <div className='images'>

                {this.props.name === 'baton_images' ?
                    (
                        <div className='image-btn first-image'>
                            {
                                images.length > 1 ?
                                    <ImageGallery thumbnailPosition={"right"} items={images.map((str, index) => ({ original: str, thumbnail: str }))} />
                                    :
                                    <img src={images[0]} alt="oeuvre" />
                            }
                        </div>
                    )
                    :
                    (text_index >= 0 ?
                        (
                            images.length > 1 ?
                                <ImageGallery thumbnailPosition={"right"} items={images.map((str, index) => ({ original: str, thumbnail: str }))} />
                                :
                                <ImageGallery showThumbnails={false} items={images.map((str, index) => ({ original: str, thumbnail: str }))} />
                        )
                        :
                        <button className='image-btn first-image' onClick={() => this.nextText(max)}>
                            <img src={images[0]} alt="premiere oeuvre" />
                        </button>
                    )
                }
                {
                    text_index < 0 ? (
                        vignette.length > 0 &&
                        <div className={`vignette side`}>
                            {vignette && vignette.map(l => <p dangerouslySetInnerHTML={{ __html: l }}></p>)}
                            {this.props.name === 'baton' &&
                                <span className='credit-photos'>Photographie : {photographie && <span dangerouslySetInnerHTML={{ __html: photographie }}></span>}</span>
                            }
                            <button className='access-peritext-btn' onClick={this.ouvrirPeritexte}>i</button>
                        </div>
                    )
                        :
                        <div className={`credit-photos`}>
                            Photographie : {photographie && <span dangerouslySetInnerHTML={{ __html: photographie }}></span>}
                        </div>


                }
            </div>
        )
    }
    backToGrotte = () => {
        this.props.closeInterface(true, '')
    }
    render() {
        const { oeuvre, texte, text_index, loading } = this.state
        const { isFirst } = this.props
        if (loading)
            return (
                <div className='window window-oeuvre'>
                    Chargement...
                </div>)
        else {
            const min = oeuvre.images.length > 0 ? -1 : 0;

            let max = 0
            if (texte.text)
                max = texte.text.length
            return (
                <>
                    <div className='window window-oeuvre'>
                        {
                            this.state.text_index < max &&
                            <div className={`content ${text_index >= 0 ? 'with-text' : 'without-text'}`}>
                                {this.renderOeuvreInfos(oeuvre, text_index, max)}
                                {this.renderTexteInfos(texte, text_index, min, max)}
                            </div >
                        }

                    </div >
                    {
                        !isFirst &&
                        <button onClick={this.backToGrotte} className='back-grotte'>Retour à la grotte</button>
                    }
                </>

            );


        }

    }
}
