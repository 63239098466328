import React, { Component } from 'react'

export default class LighteningInterface extends Component {
    componentDidMount = () => {
        setTimeout(this.props.closeInterface, 4000)
    }
    render() {
        const { fadein } = this.props
        return (
            <div className={`lightening ${fadein ? "fadein" : ""}`}>

            </div>
        )
    }
}
