import React, { Component } from "react";
import { getOeuvre } from "../../services/api.service";

export default class Oeuvre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oeuvre: null,
        }
    }
    componentDidMount = () => {
        this.getOeuvre()
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.name !== this.props.name)
            this.getOeuvre()
    }
    getOeuvre = () => {
        const oeuvre = getOeuvre(this.props.name);
        this.setState({ oeuvre })
    }
    render() {
        const { position, rotation, width, height } = this.props.coordinates
        let img = ""
        if (this.state.oeuvre && this.state.oeuvre.images.length > 0)
             img = this.state.oeuvre.preview
        const { name } = this.props
        return (
            <a-entity id={`oeuvre-${name}`} nt2-debug className="oeuvre-preview" position={position} rotation={rotation} oeuvre={`name: ${name}`}>
                <a-image src={img} width={width} height={height}></a-image>
            </a-entity>
        )

    }
}