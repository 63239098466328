import React, { Component } from 'react'
import { BiographieSingleInterface } from '.'
import { getAuteurByName, getPeritexteByName } from '../../services/api.service';

export default class PeritexteInterface extends Component {
    constructor(props) {
        super(props);
        this.state = {
            peritexte: {},
        }
    }

    componentDidMount = () => {
        this.getPeritexte()
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.name !== this.props.name) {
            this.getPeritexte()
        }
    }
    getPeritexte = () => {
        const { name } = this.props
        const peritexte = getPeritexteByName(name)
        this.setState({ peritexte })
    }
    reouvrirThisPeritexte = () => {
        this.props.openInterface(<PeritexteInterface {...this.props} />, 'fog')
    }
    ouvrirAuteur = (e) => {
        e.preventDefault();
        const name = e.target.dataset.auteur_name
        this.props.openInterface(<BiographieSingleInterface {...this.props} auteur_key={name} callback={this.reouvrirThisPeritexte} />, 'fog')
    }
    render() {
        const { mot_artiste, mot_commissaire, nom_artiste, nom_commissaire, title } = this.state.peritexte
        const artiste = getAuteurByName(nom_artiste)
        const commissaire = getAuteurByName(nom_commissaire)
        return (
            <div className='window window-peritexte' >
                <div className='peritexte-content'>
                    <h1>{title}</h1>
                    <div className='mot-commissaire'>
                        <h2>Mot du commissaire</h2>
                        <div className='mot-commissaire-content'>
                            {mot_commissaire && mot_commissaire.map(l => <p dangerouslySetInnerHTML={{ __html: l }}></p>)}
                        </div>
                        <div className='nom' onClick={this.ouvrirAuteur} data-auteur_name={nom_commissaire}>{`${commissaire.prenom} ${commissaire.nom}`}</div>
                    </div>
                    <div className='mot-artiste'>
                        <h2>Mot de l'artiste</h2>
                        <div className='mot-artiste-content'>
                            {mot_artiste && mot_artiste.map(l => <p dangerouslySetInnerHTML={{ __html: l }}></p>)}
                        </div>
                        <div className='nom' onClick={this.ouvrirAuteur} data-auteur_name={nom_artiste}>{`${artiste.prenom} ${artiste.nom}`}</div>
                    </div>
                </div>
                <button className='return-btn' onClick={this.props.goBack}>Retour</button>
            </div>
        )
    }
}
