import React, { Component } from 'react'
import { createRef } from 'react/cjs/react.production.min'
import caverne from '../../files/audio/caverne.mp3'
import menu from '../../files/audio/menu.mp3'
import sortie from '../../files/audio/sortie.mp3'

export default class Audio extends Component {
    constructor(props) {
        super(props)
        this.ref = createRef(null)
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.zone !== prevProps.zone) {
            this.ref.current.load();
            this.ref.current.play();
        }
    }

    render() {
        const { setAudio, zone, active } = this.props
        return (
            <div className='audio'>

                <audio ref={this.ref} autoPlay={true} muted={!active} loop>
                    {
                        zone === 'menu' ?
                            <source src={menu} type="audio/mpeg" />
                            :
                            zone === 'caverne' ?
                                <source src={caverne} type="audio/mpeg" />
                                :
                                zone === 'sortie' &&
                                <source src={sortie} type="audio/mpeg" />
                    }
                    Your browser does not support the audio element.
                </audio>
                <button onClick={setAudio}>{active ? "🔈" : "🔇"}</button>
            </div>
        )
    }
}
