import React, { Component } from 'react';
import { AccueilInterface } from '.';
export default class GuideInterface extends Component {
    retourAccueil = () => {
        this.props.openInterface(<AccueilInterface {...this.props} />, 'fog')
    }
    render() {
        return (
            <div className='window window-guide'>
                <h1>Guide de navigation de l’expo en ligne Le legs</h1>
                <div className='guide-content'>
                    <h2>Comment ça fonctionne?</h2>
                    <p>Pour vous d&eacute;placer d&rsquo;une chambre de la caverne &agrave; une autre, cliquez sur les fl&egrave;ches pr&eacute;sentes sur le sol de la caverne.</p>
                    <p>Pour inspecter les chambres de la caverne, vous pouvez faire usage des fl&egrave;ches directionnelles du clavier (&larr;&rarr;&uarr;&darr;) ou les touches A, D, W et S. Vous pouvez aussi cliquer et tirer &agrave; l&rsquo;aide de votre souris ou de votre pav&eacute; tactile.&nbsp;</p>
                    <p>Soyez &agrave; l&rsquo;aff&ucirc;t des &oelig;uvres tout au long de votre parcours: elles apparaissent sous forme d&rsquo;hologrammes pr&egrave;s des parois de la caverne. En cliquant sur celles-ci, vous acc&eacute;derez aux textes et &oelig;uvres visuelles de l&rsquo;exposition: cliquer sur le &laquo;i&raquo; situ&eacute; sous la vignette de l&rsquo;&oelig;uvre vous donnera acc&egrave;s au mot du commissaire ainsi qu&rsquo;&agrave; un mot de l&rsquo;artiste relatif &agrave; sa posture; cliquer sur les fl&egrave;ches situ&eacute;es sous le texte vous permettra de poursuivre votre lecture.&nbsp;</p>
                    <p>Dans le coin sup&eacute;rieur droit de votre &eacute;cran, lorsque vous vous d&eacute;placez dans la caverne, vous verrez cinq (5) carr&eacute;s. &Agrave; chaque visite d&rsquo;une nouvelle &oelig;uvre accompagn&eacute;e de son texte, un de ces indicateurs se remplira. Une fois que tous les carr&eacute;s seront remplis, une derni&egrave;re &oelig;uvre appara&icirc;tra quelque part dans la caverne.&nbsp;</p>
                    <p>Conseil: tentez de vous souvenir o&ugrave; se trouve l&rsquo;entr&eacute;e de la grotte&hellip; cela pourrait vous &ecirc;tre utile!</p>
                    <p>Pour une meilleure exp&eacute;rience de navigation, vous &ecirc;tes invit&eacute;&middot;e&middot;s &agrave; utiliser un ordinateur personnel, l&rsquo;exposition n&rsquo;&eacute;tant pas adapt&eacute;e aux dispositifs mobiles. Les fureteurs Firefox et Chrome sont &agrave; privil&eacute;gier. De plus, il est sugg&eacute;r&eacute; de naviguer en mode plein &eacute;cran.&nbsp;</p>

                </div>
                <button className='return-btn' onClick={this.retourAccueil}>Retour</button>

            </div>
        );
    }
}
