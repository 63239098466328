import React, { Component } from 'react';

//Custom components
import VRScene from './VrScene'
import { Interface, Audio } from './components';
import { AccueilInterface, AProposInterface, HUD, LighteningInterface, OeuvreInterface, TransitionInterface } from './components/interfaces';
import { getListOeuvres, getAllLocationsImages, getLocation, getLocationsImages } from './services/api.service';

//Styles
import './static_assets/styles/style.css'
import "react-image-gallery/styles/css/image-gallery.css";

export default class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show_interface: true,
            interface_content: <AccueilInterface closeInterface={this.closeInterface} firstOeuvreClose={this.callback_first} openInterface={this.openInterface} setAudioZone={this.setAudioZone} />,
            interface_background: "fog",
            oeuvre_seen: [],
            last_oeuvre: {},
            show_last_oeuvre: false,
            oeuvres: [],
            active_scene: "Point5",
            audio_active: false,
            audio_zone: 'menu',
            first_time_audio: false,
            preloading: false
        }
        this.oeuvre_pas_grotte = ["burning_earth", "baton", "baton_images", "outro"]

    }
    preloadImages = async () => {
        const { active_scene } = this.state

        const images = getLocationsImages(active_scene)
        for (let index = 0; index < images.length; index++) {
            const src = images[index];
            const img = new Image();
            img.src = src;
        }

    }
    closeInterface = (show_transition_after = false, text_transition = '', callback = null) => {
        if (show_transition_after) {
            this.setState({ interface_content: <TransitionInterface text_transition={text_transition} closeInterface={callback ? callback : this.closeInterface} /> },
                () => {
                    if (text_transition.length === 0) {
                        if (!callback) {
                            setTimeout(() => {
                                this.setState({ show_interface: false, interface_content: null },
                                    () => {
                                        if (this.state.oeuvre_seen.length === Object.keys(this.state.oeuvres).length - (this.oeuvre_pas_grotte.length + 1)) {
                                            setTimeout(this.lastScene, 500)
                                        }
                                    })
                            }, 2000)
                        }
                        else {
                            setTimeout(callback, 2000);
                        }
                    }
                    if ((this.state.oeuvre_seen.length === this.state.oeuvres.length - 1) && !this.state.show_last_oeuvre) {
                        setTimeout(this.lastScene, 1000)
                    }
                    // else if (callback) {
                    //     setTimeout(callback, 2000)
                    // }
                }
            )
        }
        else {
            this.setState({ show_interface: false, interface_content: null },
                () => {
                    if ((this.state.oeuvre_seen.length === this.state.oeuvres.length - 1) && !this.state.show_last_oeuvre) {
                        setTimeout(this.lastScene, 1000)
                    }
                })
        }


    }
    openInterface = (interface_content, interface_background = "", show_transition_before = false) => {
        if (show_transition_before) {
            this.setState({ interface_content: <TransitionInterface /> },
                () => {
                    setTimeout(() => {
                        this.setState({ interface_content, interface_background, show_interface: true })
                    }, 3000)
                }
            )
        } else {
            this.setState({ interface_content, interface_background, show_interface: true })
        }
    }
    addOeuvreSeen = (oeuvre_name) => {
        if (oeuvre_name !== "baton_images") {
            let { oeuvre_seen } = this.state
            if (oeuvre_seen.indexOf(oeuvre_name) === -1)
                oeuvre_seen = [...oeuvre_seen, oeuvre_name];
            this.setState({ oeuvre_seen })
        }
    }
    setActiveScene = name => {
        this.setState({ active_scene: name }, this.preloadImages)
    }
    componentDidMount = () => {
        this.reset();
        // this.preloadImages()
        window.addEventListener('blur', () => this.setState({ audio_active: false }));
        window.addEventListener('focus', () => this.setState({ audio_active: true }));
    }
    lastScene = () => {
        let { oeuvres, last_oeuvre } = this.state
        oeuvres[last_oeuvre.name] = last_oeuvre.content
        this.setState({ oeuvres, show_last_oeuvre: true })
        this.openInterface(<LighteningInterface fadein closeInterface={this.closeInterface} openInterface={this.props.openInterface} setAudioZone={this.setAudioZone} />)
        this.setAudioZone('sortie');
    }
    callback_first = (show_transition_after = false, text_transition = '') => {
        this.openInterface(<LighteningInterface closeInterface={this.closeInterface} openInterface={this.props.openInterface} setAudioZone={this.setAudioZone} />)
    }
    callback_lastoeuvre = () => {
        this.openInterface(<OeuvreInterface name="outro" closeInterface={() => this.closeInterface(true, '', this.close_last_oeuvre)} openInterface={this.props.openInterface} setAudioZone={this.setAudioZone} />, 'fog', true)
    }
    close_last_oeuvre = () => {
        this.reset(() => { this.openInterface(<AProposInterface firstOeuvreClose={this.callback_first} closeInterface={this.closeInterface} openInterface={this.openInterface} setAudioZone={this.setAudioZone} />, 'fog') })
    }
    reset = (callback = null) => {
        const key_last_oeuvre = "memoire"
        let oeuvres = getListOeuvres();
        const last_oeuvre = oeuvres[key_last_oeuvre];
        this.setState({
            show_interface: true,
            interface_content: <AccueilInterface firstOeuvreClose={this.callback_first} closeInterface={this.closeInterface} openInterface={this.openInterface} setAudioZone={this.setAudioZone} />,
            interface_background: "fog",
            oeuvre_seen: [],
            oeuvres,
            last_oeuvre: {
                name: key_last_oeuvre,
                content: last_oeuvre,
            },
            show_last_oeuvre: false,
            active_scene: "Point5",

        }, () => {
            if (callback) {
                callback();
            } else {
                this.setAudioZone('menu');
            };
        })
    }
    setAudio = () => {
        this.setState({ audio_active: !this.state.audio_active })
    }
    startAudio = () => {
        if (!this.state.first_time_audio) {
            this.setState({ audio_active: true, first_time_audio: true })
        }
    }
    setAudioZone = (audio_zone) => {
        this.setState({ audio_zone })
    }
    render() {
        const { show_interface, show_last_oeuvre, interface_content, interface_background, oeuvre_seen, oeuvres, last_oeuvre, active_scene, audio_active, audio_zone, preloading } = this.state
        const nb_oeuvres_total = show_last_oeuvre ? Object.keys(oeuvres).length - this.oeuvre_pas_grotte.length : Object.keys(oeuvres).length - (this.oeuvre_pas_grotte.length + 1);
        console.log(preloading)
        return (
            preloading ?
                <div className='legs-app'>
                    "CHARGEMENT..."
                </div>
                :
                <div className='legs-app' onClick={this.startAudio}>

                    <VRScene show_last_oeuvre={show_last_oeuvre} last_oeuvre={last_oeuvre} active_scene={active_scene} setActiveScene={this.setActiveScene} openInterface={this.openInterface} closeInterface={this.closeInterface} addOeuvreSeen={this.addOeuvreSeen} callback_lastoeuvre={this.callback_lastoeuvre} setAudioZone={this.setAudioZone} />
                    <Interface show={show_interface} background={interface_background} closeInterface={this.closeInterface} openInterface={this.openInterface} >
                        {interface_content}
                    </Interface>
                    {
                        interface_content === null &&
                        <HUD nb_oeuvres_seen={oeuvre_seen.length} nb_oeuvres_total={nb_oeuvres_total} closeInterface={this.closeInterface} openInterface={this.openInterface} backToHome={this.reset} />
                    }
                    <Audio active={audio_active} zone={audio_zone} setAudio={this.setAudio} />
                </div>
        );
    }
}
