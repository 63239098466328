import React, { Component } from 'react'

export default class PointsBar extends Component {
    render() {
        const { nb_found, nb_total } = this.props
        return (
            <div className='points-bar'>
                {
                    Array.from(Array(nb_total).keys()).map((i) =>
                        <div className={`point ${i < nb_found ? 'active' : 'inactive'}`}></div>
                    )
                }
            </div>
        )
    }
}
