import React, { Component } from 'react';
import { AccueilInterface } from '.';
export default class AProposInterface extends Component {
    retourAccueil = () => {
        this.props.setAudioZone('menu');
        this.props.openInterface(<AccueilInterface {...this.props} />, 'fog')
    }
    render() {
        return (
            <div className="window window-a-propos">
                <h1>À Propos</h1>
                {/* <div className='a-propos-section presentation'>
                    <h2 className='a-propos-section-title'>
                        <a href="https://kwahiatonhk.com/exposition-le-legs/" target="_blank">Présentation du projet</a>
                        <img src={external_link} alt="" />                    </h2>
                </div> */}
                <div className='a-propos-section credits'>
                    {/* <h2 className='a-propos-section-title'>À Propos</h2> */}
                    <div className='credit'>
                        <label className='credit-label'>Id&eacute;e originale et m&eacute;tatexte</label>
                        <p className='credit-value'>Louis-Karl Picard-Sioui</p>
                    </div>
                    <div className='credit'>
                        <label className='credit-label'>Textes</label>
                        <p className='credit-value'>Marie-Andr&eacute;e Gill, Michel Jean, J. D. Kurtness, Virginia P&eacute;s&eacute;map&eacute;o Bordeleau, Isabelle Picard, Louis-Karl Picard-Sioui et Christine Sioui Wawanoloath</p>
                    </div>
                    <div className='credit'>
                        <label className='credit-label'>&OElig;uvres visuelles</label>
                        <p className='credit-value'>Eruoma Awashish, Sarah Cleary, Martin Akwiranoron Loft, Virginia P&eacute;s&eacute;map&eacute;o Bordeleau, Teharihulen Michel Savard, Manon Sioui et Christine Sioui Wawanoloath</p>
                    </div>
                    <div className='credit'>
                        <label className='credit-label'>Musique originale</label>
                        <p className='credit-value'>Marc Vall&eacute;e</p>
                    </div>
                    <div className='credit'>
                        <label className='credit-label'>Direction &eacute;ditoriale</label>
                        <p className='credit-value'>Marie-Andr&eacute;e Gill</p>
                    </div>
                    <div className='credit'>
                        <label className='credit-label'>Commissariat visuel</label>
                        <p className='credit-value'>Louis-Karl Picard-Sioui</p>
                    </div>
                    <div className='credit'>
                        <label className='credit-label'>R&eacute;vision linguistique</label>
                        <p className='credit-value'>Cassandre Sioui et Catherine Lemay</p>
                    </div>
                    <div className='credit'>
                        <label className='credit-label'>D&eacute;veloppement web</label>
                        <p className='credit-value'>Ariane Guay, John Boyle-Singfield (Laboratoire NT2): programmation, interfa&ccedil;age et graphisme suppl&eacute;mentaire.</p>
                    </div>
                    <div className='credit'>
                        <label className='credit-label'>Coordination des &eacute;quipes et consultation</label>
                        <p className='credit-value'>Benoit Bordeleau (Litt&eacute;rature qu&eacute;b&eacute;coise mobile)</p>
                        <p className='credit-value'>Gina Cortopassi (Laboratoire NT2)</p>
                    </div>
                    <div className='credit last'>
                        <p className='credit-value'>Une r&eacute;alisation de Kwahiatonhk! et de Litt&eacute;rature qu&eacute;b&eacute;coise mobile, avec l&rsquo;appui financier du Conseil des arts du Canada et du Conseil de recherches en sciences humaines du Canada.&nbsp;</p>
                    </div>
                </div>
                {/* <div className='a-propos-section coordonnees'>
                    <h2 className='a-propos-section-title'>Coordonnées web et médias sociaux des partenaires</h2>
                </div> */}
                <button className="return-btn" onClick={this.retourAccueil}>Retour</button>


            </div >
        );
    }
}
