import React, { Component } from 'react';
import { AccueilInterface, BiographieSingleInterface } from '.';
import { getAllAuteurs } from '../../services/api.service';
export default class BiographiesInterface extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auteurs_list: {},
        }
    }
    componentDidMount = () => {
        this.getAllAuteurs()
    }
    retourAccueil = () => {
        this.props.openInterface(<AccueilInterface {...this.props} />, 'fog', false)
    }
    openAuteur = (evt) => {
        evt.preventDefault();
        const id = evt.target.dataset.key;
        const callback = () => this.props.openInterface(<BiographiesInterface {...this.props} />, 'fog', false)
        this.props.openInterface(<BiographieSingleInterface callback={callback} auteur_key={id} {...this.props} />, 'fog', false)
    }
    getAllAuteurs = () => {
        const auteurs_list = getAllAuteurs();
        this.setState({
            auteurs_list
        })
    }
    render() {
        const { auteurs_list } = this.state
        return (
            <div className="window window-biographies">
                <h1>Biographies des auteur·ices et artistes</h1>
                <div className='content'>
                    {
                        Object.keys(auteurs_list).map((key) => {
                            const auteur = auteurs_list[key]
                            return (
                                <button className='auteur-link-btn' data-key={key} onClick={this.openAuteur}>
                                    {`${auteur.prenom} ${auteur.nom}`}
                                </button>)
                        }
                        )
                    }
                </div>
                <button className="return-btn" onClick={this.retourAccueil}>Retour</button>

            </div>
        );
    }
}
