import React, { Component } from 'react'
import { getAuteurByName } from '../../services/api.service';

export default class BiographieSingleInterface extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auteur: null,
        }
    }
    componentDidMount = () => {
        this.getAuthor();
    }
    getAuthor = () => {
        const { auteur_key } = this.props
        const auteur = getAuteurByName(auteur_key);
        this.setState({ auteur })
    }
    render() {
        const { auteur } = this.state
        return (

            auteur ?
                <div className="window window-bio" >
                    <h1 className='bio-name'>{`${auteur.prenom} ${auteur.nom}`}</h1>
                    <div className='bio-content' dangerouslySetInnerHTML={{ __html: auteur.biographie }}></div>
                    <button className='return-btn' onClick={this.props.callback}>
                        Retour
                    </button>
                </div >
                :
                <div className='window'>
                    Chargement...
                </div>
        )
    }
}
