import React, { Component } from 'react'

export default class TransitionInterface extends Component {
  render() {
    const { text_transition } = this.props
    return (
      <div className='transition'>
        {/* {(text_transition && text_transition.length > 0) &&
          <button className='transition-close-btn' onClick={this.props.closeInterface}>✕</button>
        } */}
        {(text_transition && text_transition.length > 0) &&
          <div className='transition-content'>
            {this.props.text_transition}
            <button className='transition-continue' onClick={this.props.closeInterface}>Continuer</button>
          </div>
        }
      </div>
    )
  }
}
