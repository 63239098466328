import locations from "../consts/locations.json";
import oeuvres from "../consts/oeuvres.json";
import auteurs from "../consts/auteurs.json";
import peritextes from "../consts/peritextes.json";
import textes from "../consts/textes.json";

export function getLocationsImages(name) {
  if (locations && locations[name]) {
    let images = [];
    // images.push(locations[name].background);
    locations[name].destinations.forEach((key) => {
      images.push(locations[key].background);
    });
    return images;
  }
  return [];
}
export function getAllOeuvresImages() {
  if (oeuvres) {
    let images = [];
    Object.keys(oeuvres).forEach((key) => {
      images = [...images, ...oeuvres[key].images];
    });
    return images;
  }
  return [];
}
export function getLocation(name) {
  if (locations[`${name}`]) {
    return locations[`${name}`];
  }
  return [];
}
export function getLocationOeuvres(location) {
  if (
    locations[`${location}`] &&
    locations[`${location}`].oeuvres &&
    locations[`${location}`].oeuvres.length &&
    locations[`${location}`].oeuvres.length > 0
  ) {
    return locations[`${location}`].oeuvres;
  }
  return [];
}
export function getListOeuvres() {
  if (oeuvres) {
    return oeuvres;
  }
  return [];
}
export function getOeuvre(name) {
  if (oeuvres[`${name}`]) {
    return oeuvres[`${name}`];
  }
  return [];
}

export function getListTransitions(location) {
  if (
    locations[`${location}`] &&
    locations[`${location}`].transitions &&
    locations[`${location}`].transitions.length &&
    locations[`${location}`].transitions.length > 0
  ) {
    return locations[`${location}`].transitions;
  }
  return [];
}
export function getAllAuteurs() {
  if (auteurs) {
    return auteurs;
  }
  return [];
}
export function getAuteurByName(auteur_name) {
  if (auteurs[`${auteur_name}`]) {
    return auteurs[`${auteur_name}`];
  }
  return [];
}

export function getAllPeritextes() {
  if (peritextes) {
    return peritextes;
  }
  return [];
}
export function getPeritexteByName(name) {
  if (peritextes[`${name}`]) {
    return peritextes[`${name}`];
  }
  return [];
}
export function getAllTextes() {
  if (textes) {
    return textes;
  }
  return [];
}
export function getTexteByName(name) {
  if (textes[`${name}`]) {
    return textes[`${name}`];
  }
  return [];
}
