import React, { Component } from 'react';
export default class Interface extends Component {
    render() {
        const { children, show, background } = this.props
        return (
            <div className={`interface ${show ? "show" : "hide"} ${background}`}>
                <div className='interface-content'>
                    {children}
                </div>
            </div>
        );
    }
}
